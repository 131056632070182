import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppProvider } from './context/AppContext'; // ajuste o caminho conforme necessário
import LoginPage from './pages/LoginPage';
import AulasPage from './pages/AulasPage';
import CapturePage from './pages/CapturePage';
import EnvioPage from './pages/EnvioPage';

const App = () => {
  return (
    <AppProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/aulas" element={<AulasPage />} />
          <Route path="/capture" element={<CapturePage />} />
          <Route path="/envio" element={<EnvioPage />} />
        </Routes>
      </Router>
    </AppProvider>
  );
};

export default App;
