import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { envioBiometria } from '../services/apiService';
import './EnvioPage.css';
import { AppContext } from '../context/AppContext';
import Button from '@material-ui/core/Button';

const EnvioPage = () => {
    const navigate = useNavigate();
    const { capturedImage, codAulaTeorica, codAluno } = useContext(AppContext);
    const [etapa, setEtapa] = useState(1); // Estado para a etapa (1 para entrada, 2 para saída)

    const handleSend = async () => {
        try {
            const token = localStorage.getItem('token');
            const cleanedImage = capturedImage.replace('data:image/jpeg;base64,', '');
            console.log('Enviando imagem...', { cleanedImage, token, codAulaTeorica, codAluno, etapa });
            const response = await envioBiometria(codAulaTeorica, codAluno, etapa, 1, 1, 1, cleanedImage);
            console.log('Resposta da API:', response);
            alert('Imagem enviada com sucesso!');
        } catch (error) {
            console.error('Erro ao enviar imagem:', error);
            alert(`Erro ao enviar imagem: ${error.response?.data?.message || error.message}`);
        }
    };

    return (
        <div className="envio-page">
            <h1>Envio de Foto</h1>
            {capturedImage && <img src={capturedImage} alt="Foto Capturada" className="photo" />}
            <div className="etapa-selection">
                <label>
                    <input 
                        type="radio" 
                        name="etapa" 
                        value={1} 
                        checked={etapa === 1} 
                        onChange={() => setEtapa(1)} 
                    />
                    Entrada
                </label>
                <label>
                    <input 
                        type="radio" 
                        name="etapa" 
                        value={2} 
                        checked={etapa === 2} 
                        onChange={() => setEtapa(2)} 
                    />
                    Saída
                </label>
            </div>
            <div className="controls">
                <Button className="send-button" variant="contained" color="primary" onClick={handleSend}>Enviar</Button>
                <Button className="retake-button" variant="contained" color="primary" onClick={() => navigate('/capture')}>Refazer</Button>
            </div>
        </div>
    );
};

export default EnvioPage;
