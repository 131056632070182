import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/apiService';
import './LoginPage.css';

const LoginPage = () => {
    const [loginInput, setLoginInput] = useState('10258478535');
    const [password, setPassword] = useState('10258478535');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        setError(''); // Reset error message
        try {
            console.log('Tentando fazer login...');
            const data = await login(loginInput, password);
            console.log('Resposta da API:', data.token);
            if (data.token) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('cpf', loginInput); // Armazena o CPF (ou login) no localStorage
                navigate('/aulas');
            } else {
                setError('Login falhou!');
            }
        } catch (error) {
            console.error('Erro ao fazer login:', error);
            setError('Erro ao fazer login. Verifique suas credenciais e tente novamente.');
        }
    };

    return (
        <div className="login-container">
            <div className="login-left">
                <h1>2T Teórico Web</h1>
                <p>Sua plataforma de captura biométrica.</p>
            </div>
            <div className="login-right">
                <h2>Fazer login</h2>
                <input
                    type="text"
                    value={loginInput}
                    onChange={(e) => setLoginInput(e.target.value)}
                    placeholder="E-mail"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Senha"
                />
                <button onClick={handleLogin}>Entrar</button>
                {error && <p className="error-message">{error}</p>}
                <p>Em caso de dúvidas de como acessar, entre em contato com a sua Auto Escola!</p>
            </div>
        </div>
    );
};

export default LoginPage;
