import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AulasPage.css';
import { formatISO } from 'date-fns';
import { AppContext } from '../context/AppContext';
import { getRetornoAula } from '../services/apiService';
import Button from '@material-ui/core/Button';

const AulasPage = () => {
    const [aulas, setAulas] = useState([]);
    const [statusEntrada, setStatusEntrada] = useState({});
    const [statusSaida, setStatusSaida] = useState({});
    const navigate = useNavigate();
    const { setCodAulaTeorica, setCodAluno } = useContext(AppContext);

    useEffect(() => {
        const fetchAulas = async () => {
            const token = localStorage.getItem('token');
            const cpf = localStorage.getItem('cpf');
            if (!token || !cpf) {
                alert('Usuário não autenticado!');
                navigate('/');
                return;
            }

            const now = new Date();
            const dataInicio = formatISO(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 4, 0, 0), { representation: 'complete' });
            const dataFim = formatISO(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 0), { representation: 'complete' });

            try {
                const response = await axios.get('/2TSistemas/AulaTeorica/Aluno', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        cpf: cpf,
                        dataInicio: dataInicio,
                        dataFim: dataFim
                    }
                });
                setAulas(response.data);

                // Fetch status for each aula
                response.data.forEach(async (aula) => {
                    const status = await getRetornoAula(aula.aulaCodigo, cpf);
                    const entrada = status.find(s => s.etapa === 1);
                    const saida = status.find(s => s.etapa === 2);
                    setStatusEntrada(prevStatus => ({
                        ...prevStatus,
                        [aula.aulaCodigo]: entrada ? { text: 'Realizada às ' + formatDate(entrada.dataHoraColeta), color: 'green' } : { text: 'Não realizada', color: 'red' }
                    }));
                    setStatusSaida(prevStatus => ({
                        ...prevStatus,
                        [aula.aulaCodigo]: saida ? { text: 'Realizada às ' + formatDate(saida.dataHoraColeta), color: 'green' } : { text: 'Não realizada', color: 'red' }
                    }));
                });
            } catch (error) {
                console.error('Erro ao buscar aulas:', error);
                alert('Erro ao buscar aulas');
            }
        };

        fetchAulas();
    }, [navigate]);

    const formatDate = (dateString) => {
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return new Date(dateString).toLocaleTimeString('pt-BR', options);
    };

    const handleCapture = (aula) => {
        setCodAulaTeorica(aula.aulaCodigo);
        setCodAluno(aula.alunoCodigo);
        navigate('/capture');
    };

    return (
        <div className="aulas-page">
            <h1>Lista de Aulas</h1>
            {aulas.length > 0 ? (
                <div className="aulas-grid">
                    {aulas.map((aula, index) => (
                        <div key={index} className="aula-item">
                            <div className="aula-card">
                                <h2>{aula.alunoNome.trim()}</h2>
                                <p><span className="label">Instrutor:</span> {aula.instrutorNome}</p>
                                <p><span className="label">Quantidade de Aulas:</span> {aula.aulaQuantidade}</p>
                                <div className="time-row">
                                    <p><span className="label">Início:</span> {formatDate(aula.aulaDataHora)}</p>
                                    <p><span className="label">Término:</span> {formatDate(new Date(new Date(aula.aulaDataHora).getTime() + aula.aulaQuantidade * 60 * 60 * 1000).toISOString())}</p>
                                </div>
                                <p><span className="label">Código da Aula:</span> {aula.aulaCodigo}</p>
                                <div className="time-row">
                                    <p style={{ color: statusEntrada[aula.aulaCodigo]?.color }}><span className="label">Entrada:</span> {statusEntrada[aula.aulaCodigo]?.text}</p>
                                    <p style={{ color: statusSaida[aula.aulaCodigo]?.color }}><span className="label">Saída:</span> {statusSaida[aula.aulaCodigo]?.text}</p>
                                </div>
                                <div className="button-container">
                                    <Button variant="contained" color="primary" onClick={() => handleCapture(aula)}>Capturar Foto</Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>Não foram encontradas aulas para o CPF cadastrado!</p>
            )}
        </div>
    );
};

export default AulasPage;
