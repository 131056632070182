import axios from 'axios';

// Base URL for the API
const BASE_URL = '/2TSistemas';

// Create an instance of axios with the base URL
const api = axios.create({
    baseURL: BASE_URL,
});

// Get token from local storage
const getToken = () => {
    return localStorage.getItem('token');
};

// Format current date and time
const getFormattedDateTime = () => {
    const now = new Date();
    return now.toISOString().split('.')[0];
};

// API call to login
console.log(api.url)
export const login = async (login, password) => {
    const url = '/Login/Aluno';
    const response = await api.post(url, { login, senha: password });
    console.log(url);
    console.log(response.url);
    return response.data;
};

// API call to send biometric data
export const envioBiometria = async (codigoAula, alunoCodigo, etapa, tipoPessoa, tipoRegistro, dedoColetado, image) => {
    const url = `/AulaTeorica/${codigoAula}/Biometria`;
    const token = getToken();
    const dataHora = getFormattedDateTime();
    const params = {
        imagem: image,
        codAulaTeorica: codigoAula,
        dedoColetado: dedoColetado,
        etapa: etapa,
        tipoPessoa: tipoPessoa,
        tipoRegistro: tipoRegistro,
        codAluno: alunoCodigo,
        dataHoraColeta: dataHora,
    };
    console.log(params)
    console.log(token)
    const config = {
        headers: {
            // data: params,
            Authorization: `Bearer ${token}`
        }
    };
    const response = await api.post(url,
        params,
        config
    );
    console.log(config)
    return response.data;
};

// API call to get aulas by CPF
export const getAulas = async (cpf) => {
    const url = '/AulaTeorica/Aluno';
    const token = getToken();
    const dataInicio = new Date().toISOString().split('T')[0] + 'T04:00:00';
    const dataFim = new Date().toISOString().split('T')[0] + 'T23:59:00';
    const params = { cpf, dataInicio, dataFim };
    const response = await api.get(url, {
        params: params,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

// API call to get aulas by period
export const getAulasPeriodo = async (cpf) => {
    const url = '/AulaTeorica/Aluno';
    const token = getToken();
    const dataInicio = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString();
    const dataFim = new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0).toISOString();
    const params = { cpf, dataInicio, dataFim };
    const response = await api.get(url, {
        params: params,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

// API call to get biometric data for a specific aula and CPF
export const getRetornoAula = async (codigo, cpf) => {
    const url = `/AulaTeorica/${codigo}/Biometria/Cpf/${cpf}`;
    const token = getToken();
    const response = await api.get(url, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};
